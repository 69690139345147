<template>
  <div class="py-4 container-fluid">
    

    <div class="row">
      <div class="col-lg-12 col-md-6 mb-md-0 mb-4">
        <trx-card
          v-if="transaction"
          title="Report Transaction"
          description=""
          :headers="['no', 'order id', 'sub total', 'admin', 'tax', 'discount', 'grand total', 'payment method', 'payment status']"
          :projects="transaction"
        />
      </div>
    </div>
  </div>
</template>
<script>
// import MiniStatisticsCard from "./components/MiniStatisticsCard.vue";
// import ProjectCard from "./components/ProjectCard.vue";
// import TimelineList from "@/components/Cards/TimelineList.vue";
import TrxCard from "./components/TrxCard.vue";
import logoXD from "@/assets/img/small-logos/logo-xd.svg";
import logoAtlassian from "@/assets/img/small-logos/logo-atlassian.svg";
import logoSlack from "@/assets/img/small-logos/logo-slack.svg";
import logoSpotify from "@/assets/img/small-logos/logo-spotify.svg";
import logoJira from "@/assets/img/small-logos/logo-jira.svg";
import logoInvision from "@/assets/img/small-logos/logo-invision.svg";
import team1 from "@/assets/img/team-1.jpg";
import team2 from "@/assets/img/team-2.jpg";
import team3 from "@/assets/img/team-3.jpg";
import team4 from "@/assets/img/team-4.jpg";
import store from '@/store';
// var moment = require("moment");
export default {
  name: "dashboard-default",
  data() {
    return {
      logoXD,
      team1,
      team2,
      team3,
      team4,
      logoAtlassian,
      logoSlack,
      logoSpotify,
      logoJira,
      logoInvision,
      dashboard: null,
      eventChart: null,
      salesChart: null,
      transactionChart: null,
      transaction: null,
      selectedEvent: '',
      dateRange: [], // Variabel untuk menyimpan rentang tanggal yang dipilih
      pickerOptions: {
        shortcuts: [
          {
            text: 'Hari Ini',
            onClick(picker) {
              const today = new Date();
              picker.$emit('pick', [today, today]); // Atur rentang tanggal ke hari ini
            },
          },
          {
            text: 'Minggu Ini',
            onClick(picker) {
              const today = new Date();
              const endOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 6);
              picker.$emit('pick', [today, endOfWeek]); // Atur rentang tanggal ke minggu ini
            },
          },
        ],
      },
      events: [],
      options: [
        { name: "Cat", id: "cat" },
        { name: "Dog", id: "dog" },
        { name: "Elephant", id: "elephant" },
        { name: "Girafe", id: "girafe" },
        { name: "Snake", id: "snake" },
        { name: "Spider", id: "spider" },
        { name: "Unicorn", id: "unicorn" },
      ],
    };
  },
  created() {
    const headers = {
      "Content-Type": "application/json",
      Authorization:
        `Bearer ${store.state.user.data.access_token}`
    };
    fetch(`${process.env.VUE_APP_API_URL}/ticket-box/transaction?eventId=${store.state.user.eventId}`, { headers })
      .then((response) => response.json())
      .then((data) => {
        

        this.transaction = data.data;

        
      });
    
  },
  
  methods: {
    
  },

  components: {
    // ChartHolderCard,
    // ReportsDoughnutChart,
    // ReportsBarChart,
    // ReportsLineChart,
    // MiniStatisticsCard,
    // ProjectCard,
    TrxCard
    // TimelineList,
  },
};
// function formatCurrency(angka) {
//   angka = Math.floor(angka)
//   var number_string = angka ? angka.toString() : "0"
//   number_string = number_string.replace('.', ',')
//   var split = number_string.split(",")
//   var sisa = split[0].length % 3
//   var rupiah = split[0].substr(0, sisa)
//   var ribuan = split[0].substr(sisa).match(/\d{3}/gi)

//     if (ribuan) {
//       var separator = sisa ? "." : "";
//       rupiah += separator + ribuan.join(".");
//     }

//     rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
//     return  "Rp."+" "+ rupiah;
// }
</script>
