import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import Dashboard from "../views/Dashboard.vue";
import SignIn from "../views/SignIn.vue";
import NotFound from "../views/NotFound.vue";
import store from "../store";
import Report from "../views/Report.vue";
import Sell from "../views/Sell.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: "/report",
    name: "Report",
    component: Report,
    meta: { requiresAuth: true },
  },
  
  {
    path: "/sign-in",
    name: "SignIn",
    component: SignIn,
    meta: { guest: true },
  },
  {
    path: "/selling",
    name: "Selling",
    component: Sell,
    meta: { requiresAuth: true },
  },
  {
    path: '/404',
    name: 'SomethingBig',
    component: NotFound
  },
  {
    path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
    redirect: '/404'
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isLogin) {
      next();
      return;
    }
    next("/sign-in");
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isLogin) {
      next("/dashboard");
      return;
    }
    next();
  } else {
    next();
  }
});
export default router
