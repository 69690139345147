<template>
  <div class="py-4 container-fluid">
    <div class="col-12">
      <div class="card card-plain">
        <div class="pb-0 card-header bg-transparent mb-4">
          <h4 class="font-weight-bolder">Selling Ticket</h4>
        </div>
        <div class="card-body">
          <form role="form" action="#" @submit.prevent="">
            <div class="row">
              <div class="col-6">
               <div class="mb-3">
                  <div
                    class="input-group input-group-outline null"
                  >
                  <input type="text"
                  id="name"
                  class="form-control"
                  placeholder="Name"
                  v-model="form.name">
                  </div>
                  
                </div>
              </div>
              <div class="col-6">
               <div class="mb-3">
                  <div
                    class="input-group input-group-outline null"
                  >
                  <input type="text"
                  id="hp"
                  class="form-control"
                  placeholder="HP"
                  v-model="form.hp">
                  </div>
                  
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="mb-3">
                  <div
                    class="input-group input-group-outline null"
                  >
                  <input type="email"
                  id="email"
                  class="form-control"
                  placeholder="Email"
                  v-model="form.email">
                  </div>
                  
                </div>
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <div
                    class="input-group input-group-outline null"
                  >
                  <input type="text"
                  id="nik"
                  class="form-control"
                  placeholder="NIK"
                  v-model="form.nik">
                  </div>
                  
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <el-select
                  filterable
                  v-model="selectedEvent"
                  size="large"
                  placeholder="Pilih Tiket"
                >
                  <el-option
                    v-for="(item, k) in events"
                    :key="k"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <div
                    class="input-group input-group-outline null"
                  >
                  <input type="number"
                  id="qty"
                  class="form-control"
                  placeholder="Jumlah Tiket"
                  v-model="form.qty">
                  </div>
                  
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="text-center">
                  <material-button
                    @click="submit('cash')" 
                    class="mt-4"
                    variant="gradient"
                    color="success"
                    fullWidth
                    size="lg"
                    >Cash</material-button
                  >
                </div>
              </div>
              <div class="col-6">
                <div class="text-center">
                  <material-button
                    @click="submit('qris')" 
                    class="mt-4"
                    variant="gradient"
                    color="info"
                    fullWidth
                    size="lg"
                    >QRIS</material-button
                  >
                </div>
              </div>
            </div>
          </form>
          <div v-if="showPopupQris" class="modal">
            <div class="modal-content">
                <span class="close" @click="closeModal">&times;</span>
                <p>ORDER ID: {{orderId}}</p>
                <img :src="qrisUrl" alt="" srcset="" height="700px">
            </div>
          </div>
          <div v-if="showPopupCash" class="modal">
            <div class="modal-content">
                <span class="close" @click="closeModal">&times;</span>
                <p>Form submitted successfully!</p>
            </div>
          </div>
          
           
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import logoXD from "@/assets/img/small-logos/logo-xd.svg";
import logoAtlassian from "@/assets/img/small-logos/logo-atlassian.svg";
import logoSlack from "@/assets/img/small-logos/logo-slack.svg";
import logoSpotify from "@/assets/img/small-logos/logo-spotify.svg";
import logoJira from "@/assets/img/small-logos/logo-jira.svg";
import logoInvision from "@/assets/img/small-logos/logo-invision.svg";
import team1 from "@/assets/img/team-1.jpg";
import team2 from "@/assets/img/team-2.jpg";
import team3 from "@/assets/img/team-3.jpg";
import team4 from "@/assets/img/team-4.jpg";
import store from "@/store";
// import MaterialInput from "@/components/MaterialInput.vue";
// import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import MaterialButton from "@/components/MaterialButton.vue";
export default {
  name: "dashboard-default",
  data() {
    return {
      form: {
        name: '',
        hp: '',
        email: '',
        nik: '',
        qty: 1,
      },
      qrisUrl: '',
      orderId: '',
      showPopupCash: false,
      showPopupQris: false,
      logoXD,
      team1,
      team2,
      team3,
      team4,
      logoAtlassian,
      logoSlack,
      logoSpotify,
      logoJira,
      logoInvision,
      dashboard: null,
      eventChart: null,
      salesChart: null,
      transactionChart: null,
      transaction: null,
      selectedEvent: "",
      events: [
        {
          id: "1",
          name: "Tes",
        },
      ]
    };
  },
  created() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.state.user.data.access_token}`,
    };
   
    fetch(`${process.env.VUE_APP_API_URL}/ticket-box/product?eventId=${store.state.user.eventId}`, { headers })
      .then((response) => response.json())
      .then((data) => {
        this.events = data.data;
      });
  },

  methods: {
    closeModal() {
      this.showPopupCash = false;
      this.showPopupQris = false // Menyembunyikan modal
    },
    async submit(method) {
      console.log(method)
      fetch(`${process.env.VUE_APP_API_URL}/ticket-box/transaction/buy`, { headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${store.state.user.data.access_token}`
        },
        method: 'POST', 
        body: JSON.stringify({
            "product_id": this.selectedEvent,
            "qty": this.form.qty,
            "payment_method": method,
            "name": this.form.name,
            "email": this.form.email,
            "hp": this.form.hp,
            "nik": this.form.nik
          })
        })
        .then(response => response.json())
        .then(async (data) => {
            if(method=='cash'){
                this.showPopupCash = true
            }else{
                this.orderId = data.data.order_id
                this.qrisUrl = data.data.payment_url
                this.showPopupQris = true
            }
        })
    },
  },

  components: {
    // MaterialInput,
    // MaterialCheckbox,
    MaterialButton,
  },
};
// function formatCurrency(angka) {
//   angka = Math.floor(angka);
//   var number_string = angka ? angka.toString() : "0";
//   number_string = number_string.replace(".", ",");
//   var split = number_string.split(",");
//   var sisa = split[0].length % 3;
//   var rupiah = split[0].substr(0, sisa);
//   var ribuan = split[0].substr(sisa).match(/\d{3}/gi);

//   if (ribuan) {
//     var separator = sisa ? "." : "";
//     rupiah += separator + ribuan.join(".");
//   }

//   rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
//   return "Rp." + " " + rupiah;
// }
</script>
