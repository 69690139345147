<template>
  <div class="py-4 container-fluid">
    <div class="row mb-4">
      <div class="col-lg-12 position-relative z-index-2">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-6">
            <mini-statistics-card v-if="dashboard"
              :title="{ text: 'Transaction Count', value: dashboard.data.summary.count}"
              detail="<span class='text-success text-sm font-weight-bolder'>+0%</span> Just updated"
              :icon="{
                name: 'weekend',
                color: 'text-white',
                background: 'dark',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <mini-statistics-card v-if="dashboard"
              :title="{ text: 'Omset', value: dashboard.data.summary.grand_total }"
              detail="<span class='text-success text-sm font-weight-bolder'>+0%</span> Just updated"
              :icon="{
                name: 'leaderboard',
                color: 'text-white',
                background: 'primary',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <mini-statistics-card v-if="dashboard"
              :title="{ text: 'Revenue', value: dashboard.data.summary.admin_fee}"
              detail="<span class='text-success text-sm font-weight-bolder'>+0%</span> Exclude admin bank"
              :icon="{
                name: 'payments',
                color: 'text-white',
                background: 'warning',
              }"
            />
          </div>
          
          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <mini-statistics-card v-if="dashboard"
              :title="{ text: 'Tax', value: dashboard.data.summary.tax }"
              detail="<span class='text-success text-sm font-weight-bolder'>+0%</span> Just updated"
              :icon="{
                name: 'receipt_long',
                color: 'text-white',
                background: 'success',
              }"
            />
          </div>
          
        </div>
       
      </div>
    </div>

    <div class="row">
      <div class="col-lg-8 col-md-6 mb-md-0 mb-4">
        <project-card v-if="transaction"
          title="Ticket Seling"
          description=""
          :headers="['No', 'Ticket', 'Stock', 'Sold']"
          :projects="transaction"
        />
      </div>
      
    </div>
  </div>
</template>
<script>
import MiniStatisticsCard from "./components/MiniStatisticsCard.vue";
import ProjectCard from "./components/ProjectCard.vue";
import logoXD from "@/assets/img/small-logos/logo-xd.svg";
import logoAtlassian from "@/assets/img/small-logos/logo-atlassian.svg";
import logoSlack from "@/assets/img/small-logos/logo-slack.svg";
import logoSpotify from "@/assets/img/small-logos/logo-spotify.svg";
import logoJira from "@/assets/img/small-logos/logo-jira.svg";
import logoInvision from "@/assets/img/small-logos/logo-invision.svg";
import team1 from "@/assets/img/team-1.jpg";
import team2 from "@/assets/img/team-2.jpg";
import team3 from "@/assets/img/team-3.jpg";
import team4 from "@/assets/img/team-4.jpg";
import store from '@/store';
// var moment = require('moment');
export default {
  name: "dashboard-default",
  created() {
    const headers = { 
      "Content-Type": "application/json" ,
      "Authorization": `Bearer ${store.state.user.data.access_token}`
    };
    fetch(`${process.env.VUE_APP_API_URL}/ticket-box/dashboard?eventId=${store.state.user.eventId}`, { headers })
      .then(response => response.json())
      .then(data => {
        data.data.summary.count = Number(data.data.transaction[0].count)
        data.data.summary.grand_total = formatCurrency(data.data.transaction[0].grand_total)
        data.data.summary.admin_fee = formatCurrency(data.data.transaction[0].admin_fee)
        data.data.summary.tax = formatCurrency(data.data.transaction[0].tax)

        

        this.transaction = data.data.ticket_sold
        this.dashboard = data

        
      
  });
  },
  data() {
    return {
      logoXD,
      team1,
      team2,
      team3,
      team4,
      logoAtlassian,
      logoSlack,
      logoSpotify,
      logoJira,
      logoInvision,
      dashboard: null,
      eventChart: null,
      salesChart: null,
      transactionChart: null,
      transaction: null
    };
  },
  
  components: {
    MiniStatisticsCard,
    ProjectCard,
  },
};
function formatCurrency(angka){
  angka = Math.floor(angka)
  var number_string = angka ? angka.toString() : "0"
  number_string = number_string.replace('.', ',')
  var split = number_string.split(",")
  var sisa = split[0].length % 3
  var rupiah = split[0].substr(0, sisa)
  var ribuan = split[0].substr(sisa).match(/\d{3}/gi)

    if (ribuan) {
      var separator = sisa ? "." : "";
      rupiah += separator + ribuan.join(".");
    }

    rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
    return  "Rp."+" "+ rupiah;

}
</script>
